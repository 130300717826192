import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"
import Footer from "../Footer"

const AddAccount = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [loading,setLoading] = useState(false)
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate()

  const onChange = (e) => {
    if (e.target.name === "login") {
      setLogin(e.target.value);
    } else if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "surname") {
      setSurname(e.target.value);
    } else {
      setPass(e.target.value);
    }
  };

  const addUser = () => {
    if (login === "" || pass === "") {
      cogoToast.error("please fill form");
    } else {
      setLoading(true)
      cogoToast.loading("Trwa dodawanie konta do systemu")
      fetch(`${BACKEND_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          lastName: surname,
          email: login,
          password: pass,
        }),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode === 200) {
          setLoading(true)
          cogoToast.success("Użytkownik został utworzony");
          setTimeout(() => {
            navigate("/")
          }, [2000]);
        } else if (response.statusCode === 400) {
          cogoToast.error("Email jest już zajęty");
        }
      });
    }
  };
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Dodaj nowego użytkownika</p>
          </div>
          <div className="login-user-placeholder">
            <input
              name="name"
              type="text"
              placeholder="Imię"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
              name="surname"
              type="text"
              placeholder="Nazwisko"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
              name="login"
              type="email"
              placeholder="E-mail"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-password-placeholder">
            <input
              name="password"
              type="password"
              placeholder="Hasło"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-password-placeholder">
            <input
              name="password"
              type="password"
              placeholder="Powtórz hasło"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <button onClick={addUser} className="login-user-button">
            Stwórz konto
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default AddAccount;
