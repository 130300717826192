import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Files from 'react-files'
import PublitioAPI from 'publitio_js_sdk'
import axios from "axios";
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "../constants"
import { useForm } from "react-hook-form";
import Footer from "../Footer"

const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')

const AddQuiz = () => {
  // const [name,setName] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      question:"",
      answer_a:"",
      answer_b:"",
      answer_c:"",
      answer_d:"",
      answer_true:""   
    },
  });
    const [data, setData] = useState([]);
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

      useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
          setLoading(true);
          setTimeout(() => {
            fetch(`${BACKEND_URL}/presentations`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": true,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.hasOwnProperty("error")) {
                  setData(response);
                }
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => setLoading(false));
          }, [1000]);
        }
      }, [data]);

    const AddQuiz = async (data) => {
      try{
        const response = await fetch(`${BACKEND_URL}/quiz`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.name,
          question:data.question,
          answer_a: data.answer_a,
          answer_b: data.answer_b,
          answer_c: data.answer_c,
          answer_d: data.answer_d,
          answer_true: data.answer_true
        }),
      });
      const result = await response.json();

      if (result.statusCode === 200) {
        setLoading(false)
        cogoToast.success(
          "Prezentacja została dodana pomyślnie"
        );
        navigate("/");
      } else {
        cogoToast.error("Nieprawidłowe dane");
      } 
    }
    catch (error) {
      console.error("erorr: ", error);
    }}

    return(
        <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Dodaj quiz</p>
          </div>
          <form onSubmit={handleSubmit(AddQuiz)}>
          <div className="login-user-placeholder">
            <input
            {...register("question", {
              maxLength: 3000,
            })}
              name="question"
              type="text"
              placeholder="Dodaj pytanie"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
            {...register("answer_a", {
              maxLength: 200,
            })}
              name="answer_a"
              type="text"
              placeholder="Odpowiedz a"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
             {...register("answer_b", {
              maxLength: 200,
            })}
              name="answer_b"
              type="text"
              placeholder="Odpowiedz b"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
             {...register("answer_c", {
              maxLength: 200,
            })}
              name="answer_c"
              type="text"
              placeholder="Odpowiedz c"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
             {...register("answer_d", {
              maxLength: 200,
            })}
              name="answer_d"
              type="text"
              placeholder="Odpowiedz d"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
             {...register("answer_true", {
              maxLength: 30,
            })}
              name="answer_true"
              type="text"
              placeholder="Prawidłowa odpowiedz"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
           <select required
                  {...register("name", {
                    required: { value: true, message: "Pole wymagane" },
                    maxLength: 30,
                  })}>
                    <option value=""></option>
              {data.map((option) => {
                return <option key={option.id} id={option.id} value={option.id}>{option.title}</option>
                 })}
            </select>
          </div>
          <input type="submit" value="Dodaj" className="login-user-button"/>
          </form>
        </div>
        </div>
        <Footer/>
    </>
    )
}
export default AddQuiz