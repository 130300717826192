import logo from "./assets/img/logo poziom białe.png";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-box container">
          <div>
           <Link to="/" className="logo_link">
               <img src={logo} className="logo" alt="logo"></img>
           </Link>
          </div>
          <div>
          <p className="footer-paragraf">Akademia Mariusz Poltorak</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
