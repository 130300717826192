import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import JsFileDownloader from 'js-file-downloader';
import { BACKEND_URL } from "./constants"
import cogoToast from "cogo-toast"
import image from "./assets/img/locker_53876-25496.avif"
// import { Fade } from "react-slideshow-image";

const Home = () => {
  const [data, setData] = useState([]);
  const [cheats,setCheats] = useState([])
  const navigate = useNavigate();
  const [userInfo,setUserInfo] = useState([])
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const initialized = useRef(false);


  const showElement = (element) => {
    if(element.level <= userInfo.level){
      navigate(`/pdf?link=${element.pdf}&id=${element.id}`)
    }
    else{
      cogoToast.info("Posiadasz za niski poziom nauki żeby to otworzyć")
    }
  }


  const onDownload = (image) => {
    new JsFileDownloader({ 
      url: image
    })
    .then(function () {
     cogoToast.success("Plik został pobrany")
      // Called when download ended
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setLoading(true);
      setTimeout(() => {
        fetch(`${BACKEND_URL}/presentations`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.hasOwnProperty("error")) {
              setData(response);
            }
          })
          .catch((error) => {
            console.error("error: ", error);
          })
          .finally(() => setLoading(false));
      }, [1000]);
    }
  }, [data]);

  useEffect(() => {
    if(user){
      fetch(`${BACKEND_URL}/cheats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
            setCheats(response.data);
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => setLoading(false));

    }
  }, []);

  useEffect(() => {
    if(user){
      fetch(`${BACKEND_URL}/users/${user?.user.userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
            setUserInfo(response[0]);
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => setLoading(false));

    }
  }, [user?.user.userId]);

  return (
    <>
      <div className="home">
        <div className="home-banner">
          {/* <Fade> */}
          <div className="home-banner-container">
            <div className="home-banner-text">
              <h1 className="home-banner-header-text">
              Akademia Mariusz Poltorak
              </h1>
              {/* <p className="home-banner-paragraf">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
              </p> */}
              <Link to="/">
                <button style={{borderRadius:"0px"}} className="button-home-calculate">
                  O firmie
                </button>
              </Link>
            </div>
            {/* <img
              className="banner-image"
              width={971}
              height={700}
              src={dashboard}
              alt="dashboard"
            /> */}
          </div>
        </div>
        <div className="home-first-section">
          <div className="container">
            <h2 style={{fontSize:"30px"}} className="home-small-header">Dostępne Moduły</h2>
            {!user && <div style={{display:"flex", flexFlow:"column", alignItems:"center"}}>
                <h3 style={{color:"white"}}>Prosze sie zalogować żeby móc zobaczyć dostępne prezentacje</h3>
                </div>
                }
                {!user && 
               <Link to="/login">
                 <button style={{fontSize:"28px",textAlign:"center"}} className="login-button">Zaloguj się</button>
               </Link>
              }
            <div className="home-first-section-elements">
               <div className="presentations-elements">
              {user && data
              // .filter((element) => element.level <= userInfo.level)
              .map((element) => {
                return(
                  <button style={{background:"transparent", border:"none",cursor:"pointer"}} key={element.id} onClick={() => showElement(element)}>
                    <div className="home-first-section-element">
                     <img alt={element.name} width={300} style={{maxWidth:"1280px", height:"200px" }} src={element.level <= userInfo.level ? element.image: image}></img>
                    </div>
                  </button>
                )
              })}
              </div>
            </div>
          </div>
        </div>
        <div className="home-second-section">
          <div className="container">
            <h2 style={{fontSize:"30px"}}  className="home-small-header">Materiały pomocnicze</h2>
            {!user && <div style={{display:"flex", flexFlow:"column", alignItems:"center"}}>
                <h2 style={{color:"white"}}>Prosze sie zalogować żeby móc zobaczyć dostępne materiały pomocnicze</h2>
                {!user && 
               <Link style={{textAlign:"center"}} to="/login">
                 <button style={{fontSize:"28px"}} className="login-button">Zaloguj się</button>
               </Link>
              }   
                </div>
                }
            <div className="home-first-section-elements">
              {cheats.map((cheat) => {
                return(
                  <div className="home-first-section-element">
                    <p style={{color:"white"}}>{cheat?.name}</p>
                    <div style={{display:"flex", flexFlow:"column", gap:"10px", alignItems:"center"}}>
                    <img alt={cheat.name} width={200} src={cheat?.image}/>
                    <button style={{cursor:"pointer"}} className="button-home-calculate" onClick={() => onDownload(cheat.image)}>Pobierz</button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* <section className="home-black-banner">
          <div className="home-black-banner-content container">
            <div className="home-black-banner-box">
              <div className="home-black-banner-box-inside">
                <span className="home-second-section-text">OUR MISSION</span>
                <p className="home-second-section-paragraf">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor.
                </p>
              </div>
              <div className="home-black-banner-paragrafs">
                <p className="home-banner-paragraf">
                  Nunc ut egestas libero, ac posuere purus. Vestibulum accumsan
                  nunc rutrum, lobortis massa sed.
                </p>
                <p className="home-banner-paragraf">
                  Facilisis mauris sit amet massa vitae tortor condimentum.
                  Pulvinar etiam non quam lacus suspendisse.
                </p>
              </div>
              <ul className="home-black-banner-list">
                <li>
                  Aliquam porta nisl dolor, molestie pellentesque elit molestie
                  in.
                </li>
                <li>
                  Vestibulum eu quam nec neque pellentesque efficitur id eget
                  nisl.
                </li>
                <li>
                  Donec sed erat ut magna suscipit mattis. Aliquam erat
                  volutpat.
                </li>
                <li>
                  Aliquam pulvinar vestibulum blandit. Donec sed nisl libero.
                </li>
                <li>Fusce dignissim luctus sem eu dapibus.</li>
              </ul>
            </div>
            <div>
              <img className="footballer" src={footballer} alt="footballer" />
            </div>
          </div>
        </section> */}
        {/* <div className="home-second-section">
          <div className="container">
            <h3 className="home-section-small-header">
              Soccer talent predictor
            </h3>
            <p className="home-third-section-paragraf">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint
            </p>
            <div className="home-third-section-elements">
              <div className="home-third-section-element">
                <img
                  className="home-first-section-icon"
                  alt="science"
                  src={potential}
                />
                <div className="home-icon-text-box">
                  <span className="home-first-section-text">Potential</span>
                  <p className="home-icon-section-box-paragraf">
                    You will adapt the training plan to your expectations
                  </p>
                </div>
              </div>
              <div className="home-third-section-element">
                <img
                  className="home-first-section-icon"
                  alt="science"
                  src={personal}
                />
                <div className="home-icon-text-box">
                  <span className="home-first-section-text">
                    Personalization
                  </span>
                  <p className="home-icon-section-box-paragraf">
                    You will adapt the training to the motor needs of the
                    players!
                  </p>
                </div>
              </div>
              <div className="home-third-section-element">
                <img
                  className="home-first-section-icon"
                  alt="science"
                  src={benefits}
                />
                <div className="home-icon-text-box">
                  <span className="home-first-section-text">Benefits</span>
                  <p className="home-icon-section-box-paragraf">
                    You will select positions for the player according to his
                    predispositions!
                  </p>
                </div>
              </div>
              <div className="home-third-section-element">
                <img
                  className="home-first-section-icon"
                  alt="science"
                  src={progres}
                />
                <div className="home-icon-text-box">
                  <span className="home-first-section-text">Progres</span>
                  <p className="home-icon-section-box-paragraf">
                    You won't miss any talent!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="home-red-section">
          <div className="container">
            <h3 className="home-red-section-small-header">CALL TO ACTION</h3>
            <p className="home-red-section-paragraf">
              To learn more about solutions supporting the development of young
              players , go to the next tab.
            </p>
            <button className="home-red-section-button">
              Check Out Products
            </button>
          </div>
        </div> */}
        {/* <section className="home-banner-sport-predictor">
          <h2 className="home-banner-sport-predictor-header">
            Why was SportPredictor created?
          </h2>
          <p className="home-banner-sport-predictor-paragraf">
            Nunc ut egestas libero, ac posuere purus. Vestibulum accumsan nunc
            rutrum, lobortis massa sed, congu
          </p>
          <div className="home-banner-sport-predictor-main container">
            <img src={notebook} className="notebook" alt="laptop" />
            <div className="home-laptop-banner-text">
              <h1 className="home-laptop-banner-header-text">
                How will soccer talent predictor affect the development of your
                players?
              </h1>
              <p className="home-laptop-paragraf">
                Nunc ut egestas libero, ac posuere purus. Vestibulum accumsan
                nunc rutrum, lobortis massa sed, congu
              </p>
              <Link to="/products">
                <button className="button-home-calculate">Read More</button>
              </Link>
            </div>
          </div>
          <div className="container">
            <div style={{ paddingTop: "110px" }}>
              <h3 className="home-section-small-header">Stapes</h3>
              <p className="home-red-section-paragraf">
                Scientific research & analyzes clearly show that
              </p>
              <p
                style={{ paddingTop: "10px" }}
                className="home-banner-sport-predictor-paragraf"
              >
                Nunc ut egestas libero, ac posuere purus. Vestibulum accumsan
                nunc rutrum, lobortis massa sed, congu Nunc ut egestas libero,
                ac posuere purus. Vestibulum accumsan nunc rutrum.
              </p>
              <div className="home-third-section-elements">
                <div className="home-next-section-element">
                  <img
                    className="home-first-section-icon"
                    alt="science"
                    src={potential}
                  />
                  <span className="home-sport-section-text">Potential</span>
                </div>
                <div className="home-next-section-element">
                  <img
                    className="home-first-section-icon"
                    alt="science"
                    src={personal}
                  />
                  <span className="home-sport-section-text">
                    Personalization
                  </span>
                </div>
                <div className="home-next-section-element">
                  <img
                    className="home-first-section-icon"
                    alt="science"
                    src={benefits}
                  />
                  <span className="home-sport-section-text">Benefits</span>
                </div>
                <div className="home-next-section-element">
                  <img
                    className="home-first-section-icon"
                    alt="science"
                    src={progres}
                  />
                  <span className="home-sport-section-text">Progres</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <div className="home-white-section">
          <div className="container">
            <h2 style={{fontSize:"30px"}}  className="home-white-section-small-header">Skontakuj sie z nami</h2>
            <button style={{fontSize:"20px"}}  className="home-white-section-button">
              Kontakt
            </button>
          </div>
        </div>
        {/* <section className="home-banner-sport-predictor">
          <h2 className="home-banner-sport-predictor-header">
            Why is it so important
          </h2>
          <p className="home-banner-sport-predictor-paragraf">
            The physical development of a young person can significantly affect
            his sports capabilities and position on the pitch.
          </p>
          <div className="container">
            <div style={{ paddingTop: "50px" }}>
              <div className="home-blog-section-elements">
                <div className="home-blog-element">
                  <img src={blog1} alt="1" />
                  <div className="home-blog-text-box">
                    <h4 className="home-blog-header">How it's working?</h4>
                    <p className="home-blog-paragraf">
                      Soccer Talent Predictor bases its analysis on the
                      interaction of a player's biological data, such as height,
                      weight, and predicted age at peak at height velocity, and
                      then predicts their motor potential at age 18, which
                      enables matching the best position for him on the pitch,
                      corresponding to his potential.
                    </p>
                  </div>
                </div>
                <div className="home-blog-element">
                  <img src={blog2} alt="2" />
                  <div className="home-blog-text-box">
                    <h4 className="home-blog-header">
                      Save Time and Money on Training with Accurate Player
                      Evaluation.
                    </h4>
                    <p className="home-blog-paragraf">
                      With Soccer Talent Predictor you can avoid having spent
                      your life working hard to be the best, wasted time and
                      money that would be invested in inappropriate training.
                    </p>
                  </div>
                </div>
                <div className="home-blog-element">
                  <img src={blog3} alt="3" />
                  <div className="home-blog-text-box">
                    <h4 className="home-blog-header">
                      Motor Prediction Guides Young Football Players to Their
                      Best Position for Professional Success.
                    </h4>
                    <p className="home-blog-paragraf">
                      The prediction of motor potential determines whether a
                      young player has a chance to practice football at a
                      professional level and determine the position on the pitch
                      where he would have the greatest chance of success.
                    </p>
                  </div>
                </div>
                <div className="home-blog-element">
                  <img src={blog4} alt="4" />
                  <div className="home-blog-text-box">
                    <h4 className="home-blog-header">
                      Soccer Talent Predictor Guides Optimal Training and
                      Development for Young Players
                    </h4>
                    <p className="home-blog-paragraf">
                      Soccer Talent Predictor allows coaches and parents to
                      properly adjust the training and development of a young
                      player's sports career to achieve the best results
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};
export default Home;
