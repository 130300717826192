import { useEffect, useRef, useState } from "react"
import { BACKEND_URL } from "../constants"

const UserProfile = () => {
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [users,setUsers] = useState([])
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
        fetch(`${BACKEND_URL}/users/${user.user.userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.hasOwnProperty("error")) {
              setUsers(response[0]);
            }
          })
          .catch((error) => {
            console.error("error: ", error);
          })
          .finally(() => setLoading(false));
        }
      },[user.user.userId])


    return(
        <div style={{paddingTop:"20px"}}>
        <h1>Profil użytkownika</h1>
        <br></br>
        <p>Imie i nazwisko: {user.user.name} {user.user.surname}</p>
        <br></br>
        <p>E-mail: {user.user.email}</p>
        <br></br>
        <p>Poziom nauki: {users?.level}</p>
        </div>
    )

}
export default UserProfile