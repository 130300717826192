import { BigButton } from "./BigButton";

export default function PagingControl({totalPages, pageNum, onPageChange}) {
  const styles= {
    container: {
      marginTop: 8,
      marginBottom: 8,
    },
    inlineFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pageInfo: {
      padding: 8,
      color: "rgb(39, 209, 209)",
      fontSize: 14,
    }
  }
  return (
    <div style={styles.container}>
      <div style={styles.inlineFlex}>
        <BigButton
          title={"<"}
          onClick={() => onPageChange(pageNum - 1)}
          disabled={pageNum===0}
        />
         <div style={styles.pageInfo}>
          Strona: {pageNum}/{totalPages}
        </div>
        <BigButton
          title={">"}
          onClick={() => {
            onPageChange(pageNum + 1)
          }}
          disabled={pageNum>totalPages-1}
        />
      </div>
    </div>
  );
}
