import './App.css';
import Header from "./Header"
import Footer from './Footer';
import { Routes, Route } from "react-router-dom";
import Home from './Home'
import PageLogin from './components/Login'
import Register from './components/Register'
import Presentation from './components/AddPresentation';
import PdfViewer from './components/PdfViewer'
import Quiz from './components/Quiz'
import AddQuiz from './components/AddQuiz'
import EditQuestions from './components/EditQuestions'
import UserProfile from './components/UserProfile'
import AddAccount from './components/AddAccount'
import AllUsers from './components/AllUsers'
import AddCheats from './components/AddCheats'

function App() {
  return (
    <div className="App">
      <Header/>
      <div className="main">
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<PageLogin />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/addPresentation" element={<Presentation />} />
          <Route exact path="/addCheat" element={<AddCheats />} />
          <Route exact path="/addAccount" element={<AddAccount />} />
          <Route exact path="/pdf" element={<PdfViewer />} />
          <Route exact path="/quiz" element={<Quiz/>} />
          <Route exact path="/addQuiz" element={<AddQuiz/>} />
          <Route exact path="/editQuestions" element={<EditQuestions/>} />
          <Route exact path="/profile" element={<UserProfile/>} />
          <Route exact path="/users" element={<AllUsers/>} />
          </Routes>
      </div>
    </div>
  );
}

export default App;
