import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "./assets/img/logo poziom białe.png";
import { useState } from "react";
import cogoToast from "cogo-toast"

const Header = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [menu, setMenu] = useState(false);
  const onMenu = () => {
    if (menu === false) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  };

  const onLogout = () => {
    sessionStorage.clear();
    cogoToast.success("Użytkownik wylogowany");
    setTimeout(() => {
      window.location.href = "/";
    }, [1500]);
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo_link">
          <img src={logo} className="logo" alt="logo"></img>
        </Link>
        <i onClick={onMenu} className="fa fa-bars"></i>
        <ul className={menu === true ? "header-nav-visible" : "header-nav"}>
          {/* <li>
            <Link className="header-link-lite" to="/">
              Strona główna
            </Link>
          </li> */}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/addPresentation">
               Dodaj Prezentacje
            </Link>
          </li>}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/addAccount">
               Dodaj Użytkownika
            </Link>
          </li>}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/users">
               Pokaż wszystkich użytkowników
            </Link>
          </li>}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/editQuestions">
               Edycja Pytania do Quizu
            </Link>
          </li>}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/addQuiz">
               Dodaj Quiz
            </Link>
          </li>}
          {user && user?.user.type === "admin" && <li>
            <Link className="header-link-lite" to="/addCheat">
              Dodaj Ściągi
            </Link>
          </li>}
          {/* <li>
            <Link className="header-link-lite" to="/">
              Kontakt
            </Link>
          </li> */}
          {!user ? (
            <>
              {/* <div>
                <Link to="/register">
                  <button className="register-button">Załóż konto</button>
                </Link>
              </div> */}
              <div>
                <Link to="/login">
                  <button className="login-button">Zaloguj się</button>
                </Link>
              </div>
            </>
          ) : null}
        </ul>
        <div className="header-button-box">
          {/* {user !== null && (
            <div>
              <Link to="/profile">
                <button className="header-button-account">Moje konto</button>
              </Link>
            </div> 
          )} */}
          {user !== null && (
            <div>
                <button onClick={onLogout} style={{backgroundColor:"red"}} className="header-button-logout">Wyloguj</button>
            </div> 
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
