import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Files from 'react-files'
import PublitioAPI from 'publitio_js_sdk'
import axios from "axios";
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "../constants"
import Footer from "../Footer"

const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')

const AddPresentation = () => {
    const [name, setName] = useState("");
    const [pdf, setPdf] = useState("");
    const [file,setFile] = useState("")
    const [level, setLevel] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleChange = (files) => {
        setPdf(files[0])
      }

    const handleError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
      }

    const onChange = (e) => {
        if (e.target.name === "name") {
          setName(e.target.value);
        }
        else if(e.target.name === "level"){
          setLevel(e.target.value)
        } 
        else if(e.target.name === "file"){
          setFile(e.target.files[0]);
        }
      };

    const uploadFile = async () => {
      let data;
      const body = new FormData();
      body.set("key", "f32652bb4b2bc7d525ff9f56256361f7");
      body.append("image", file);
      try{
        let resp;
        setLoading(true)
        cogoToast.loading("Trwa dodawanie prezentacji do systemu")
        resp = await axios({
          method: "POST",
          url: "https://api.imgbb.com/1/upload",
          data: body,
        });
        data = await publitio.uploadFile(pdf, 'file')
        const response = await fetch(`${BACKEND_URL}/presentation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          pdf: data.url_preview,
          file: resp ? resp.data.data.url : null,
          level: level
        }),
      });
      const result = await response.json();

      if (result.statusCode === 200) {
        setLoading(false)
        cogoToast.success(
          "Prezentacja została dodana pomyślnie"
        );
        navigate("/");
      } else {
        cogoToast.error("Nieprawidłowe dane");
      } 
    }
    catch (error) {
      console.error("erorr: ", error);
    }}

    return(
        <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Dodaj prezentacje</p>
          </div>
          <div className="login-user-placeholder">
            <input
              name="name"
              type="text"
              placeholder="Tytuł prezentacji"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
            style={{borderRadius:"0px"}}
              name="level"
              type="number"
              placeholder="Poziom trudności"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div style={{ maxWidth: "100%" }} className="col-lg-12 mb-2">
            <div className="form-group">
              <label
                style={{ display: "flex", flexFlow: "column",alignItems:'center', fontSize:"20px" }}
                className="text-label"
                >Zdjęcie
                <input
                className="custom-file-input"
                  type="file"
                  name="file"
                  style={{ width: "400px", height: "45px" }}
                  onChange={onChange}
                  id="inputGroupPrepend2"
                  aria-describedby="inputGroupPrepend2"
                />
                </label>
            </div>
          </div>
          <div className="files">
      <Files
        className='files-dropzone'
        onChange={handleChange}
        onError={handleError}
        accepts={['image/png', '.pdf', 'audio/*']}
        multiple
        maxFileSize={10000000}
        minFileSize={0}
        clickable>
          Prezentacja w pdfie
      </Files>
    </div>
          <button onClick={uploadFile} className="login-user-button">
            Dodaj
          </button>
          <div className="login-icon-box">
          </div>
        </div>
      </div>
      <Footer/>
    </>
    )
}
export default AddPresentation