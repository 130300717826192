// import login from "../../../assets/img/Group 271.png";
import { useState } from "react";
// import password from "../../../assets/img/auth/Group 273.png";
// import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"
import Footer from "../Footer"

const LoginUser = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate()

  const onChange = (e) => {
    if (e.target.name === "login") {
      setLogin(e.target.value);
    } else if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "surname") {
      setSurname(e.target.value);
    } else {
      setPass(e.target.value);
    }
  };

//   const ForgotPassword = () => {
//     fetch(
//       `${process.env.REACT_APP_API}/api/Account/ForgotPassword?Email=${login}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//       .then((res) => {
//         if (res.ok) {
//           cogoToast.success(
//             "Instukcja zmiany hasła została wysłana na podanego maila"
//           );
//           return res.json();
//         } else {
//           cogoToast.error("Podany email nie istnieje w naszej bazie danych");
//         }
//       })
//       .catch((error) => {
//         console.error("error: ", error);
//       });
//   };

  const addUser = () => {
    if (login === "" || pass === "") {
      cogoToast.error("please fill form");
    } else {
      fetch(`${BACKEND_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          lastName: surname,
          email: login,
          password: pass,
        }),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode === 200) {
          cogoToast.success("Użytkownik utworzony. Teraz możesz sie zalogować");
          setTimeout(() => {
            navigate("/login")
          }, [2000]);
        } else if (response.statusCode === 400) {
          cogoToast.error("Email jest już zajęty");
        }
      });
    }
  };
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Zarejestruj się</p>
          </div>
          <div className="login-user-placeholder">
            <input
              name="name"
              // type="text"
              placeholder="Imię"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
              name="surname"
              // type="text"
              placeholder="Nazwisko"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
              name="login"
              type="email"
              placeholder="E-mail"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-password-placeholder">
            <input
              name="password"
              type="password"
              placeholder="Hasło"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-password-placeholder">
            <input
              name="password"
              type="password"
              placeholder="Powtórz hasło"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <button onClick={addUser} className="login-user-button">
            Stwórz konto
          </button>
          <div className="login-icon-box">
            <div className="login-user-paragraf-container">
              <p className="login-user-paragraf-container-text">
                Masz już konto?
              </p>
              <a
                href="/login"
                className="login-user-paragraf-span-text"
              >
                Zaloguj się
              </a>
            </div>
            {/* <img alt="icons" className="icons" src={icons}></img> */}
            {/* <Link to="/google">
              <img alt="icons" className="icons" src={google}></img>
            </Link> */}
            {/* <div className="google-box">
              <GoogleLogin
                clientId={clientId}
                buttonText="Sign in with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                isSignedIn={true}
              />
            </div> */}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default LoginUser;
