import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Files from 'react-files'
import PublitioAPI from 'publitio_js_sdk'
import axios from "axios";
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "../constants"
import { useForm } from "react-hook-form";
import Footer from "../Footer"

const EditQuestions = () => {
  // const [name,setName] = useState("")
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      question:"", 
    },
  });
    const [questions, setQuestions] = useState([]);
    const [presentations, setPresentations] = useState([]);
    const [id, setId] = useState(0)
    const [question,setQuestion]= useState("")
    const [answer_a,setAnswer_a]= useState("")
    const [answer_b,setAnswer_b]= useState("")
    const [answer_c,setAnswer_c]= useState("")
    const [answer_d,setAnswer_d]= useState("")
    const [answer, setAnswer] = useState("")
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    const onChange = (e) => {
      if(e.target.name === "question"){
        setQuestion(e.target.value)
      }
      else if(e.target.name === "a"){
        setAnswer_a(e.target.value)
      }
      else if(e.target.name === "b"){
        setAnswer_b(e.target.value)
      }
      else if(e.target.name === "c"){
        setAnswer_c(e.target.value)
      }
      else if(e.target.name === "d"){
        setAnswer_d(e.target.value)
      }
      else if(e.target.name === "answer_true"){
        setAnswer(e.target.value)
      }
      else{
        setId(e.target.value)
      }
    }

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        setLoading(true);
        setTimeout(() => {
          fetch(`${BACKEND_URL}/presentations`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": true,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (!response.hasOwnProperty("error")) {
                setPresentations(response);
              }
            })
            .catch((error) => {
              console.error("error: ", error);
            })
            .finally(() => setLoading(false));
        }, [1000]);
      }
    }, [presentations]);

      useEffect(() => {
        // if (!initialized.current) {
        //   initialized.current = true;
        //   setLoading(true);

         setTimeout(() => {
            fetch(`${BACKEND_URL}/quiz`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": true,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.hasOwnProperty("error")) {
                  setQuestions(response.data);
                }
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => setLoading(false));
          }, [1000]);
      }, []);

      const onDelete = async (id) => {
        try{
          const response = await fetch(`${BACKEND_URL}/delete-question`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
          }),
        });
        const result = await response.json();
  
        if (result.statusCode === 200) {
          setLoading(false)
          cogoToast.success(
            "Pytanie zostało usunięte"
          );
          navigate("/");
        } else {
          cogoToast.error("Nieprawidłowe dane");
        } 
      }
      catch (error) {
        console.error("erorr: ", error);
      }}


    const EditQuestions = async (element) => {
      try{
        const response = await fetch(`${BACKEND_URL}/quiz`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: element.id,
          question:question !== "" ? question: element.name,
          answer_a:answer_a !== "" ? answer_a : element.answer_a,
          answer_b:answer_b !== "" ? answer_b : element.answer_b,
          answer_c:answer_c !== "" ? answer_c : element.answer_c,
          answer_d:answer_d !== "" ? answer_d : element.answer_d,
          answer_true:answer !== "" ? answer : element.answer_true,
          presentation_id: element.presentation_id
        }),
      });
      const result = await response.json();

      if (result.statusCode === 200) {
        setLoading(false)
        cogoToast.success(
          "Dane quizu zaaktualizowane"
        );
        navigate("/");
      } else {
        cogoToast.error("Nieprawidłowe dane");
      } 
    }
    catch (error) {
      console.error("erorr: ", error);
    }}

    return(
        <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Edycja pytań</p>
          </div>
          <div className="login-user-placeholder">
           <select onChange={onChange} required>
            <option value=""></option>
              {presentations !== undefined  && presentations.map((option) => {
                return <option key={option.id} id={option.id} value={option.id}>{option.title}</option>
                 })}
            </select>
          </div>
          {questions
          .filter((question) => question.presentation_id === parseInt(id) )
          .map((question) => {
            return(
              <div className="login-user-placeholder">
                <div style={{display:"flex", flexFlow:'column', alignItems:'center'}}>
            <p style={{color:"black"}}> Pytanie: {question.name}</p>
            <textarea 
              name="question"
              id={question.id}
              onChange={onChange}
              className="login-user-line"
            />
            <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             <button className="btn btn-danger" onClick={() => onDelete(question.id)}>Usuń</button>
             </div>
             <br></br>
             <p style={{color:"black"}}>Opcja a: {question.answer_a}</p>
            <textarea name="a" onChange={onChange}
              className="login-user-line"/>
                <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             </div>
               <br></br>
            <p style={{color:"black"}}> Opcja b: {question.answer_b}</p>
            <textarea name="b" onChange={onChange}
              className="login-user-line" type="text"/>
                <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             </div>
               <br></br>
            <p style={{color:"black"}}> Opcja c: {question.answer_c}</p>
            <textarea name="c" onChange={onChange}
              className="login-user-line" type="text"/>
               <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             </div>
               <br></br>
            <p style={{color:"black"}}> Opcja d: {question.answer_d}</p>
            <textarea name="d" onChange={onChange}
              className="login-user-line" type="text"/>
                 <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             </div>
             <br></br>
             <p style={{color:"black"}}> Poprawna odpowiedz: {question.answer_true}</p>
            <textarea name="answer_true" onChange={onChange}
              className="login-user-line" type="text"/>
                 <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             <button className="btn btn-primary" onClick={() => EditQuestions(question)}>Zauktualizuj zmiany</button>
             </div>
            </div>
          </div>
            )
          })}
          {/* <input type="submit" value="Dodaj" className="login-user-button"/> */}
        </div>
        </div>
        <Footer/>
    </>
    )
}
export default EditQuestions