import cogoToast from "cogo-toast"
import { useEffect, useRef, useState } from "react"
import { useResolvedPath } from "react-router-dom"
import { BACKEND_URL } from "../constants"
import Footer from "../Footer"
import UserProfile from "./UserProfile"

const AllUsers = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([])
    const info = JSON.parse(sessionStorage.getItem("user"));
    const [activeIndex,setActiveIndex] = useState(-1)
    // const [achieve, setAchieve] = useState([])
    const initialized = useRef(false);


    const onChange = (e) => {
      setActiveIndex(parseInt(e.target.value))
    }

    const deleteUser = (id) => {
      fetch(`${BACKEND_URL}/deleteUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           cogoToast.success("Użytkownik został usunięty")
            window.location.reload()
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
          setLoading(true);
          cogoToast.loading('Trwa wczytywanie listy użytkowników')
          setTimeout(() => {
            fetch(`${BACKEND_URL}/achievements`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": true,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.hasOwnProperty("error")) {
                  setUsers(response.data);
                }
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => setLoading(false));
          }, [1000]);
        }
      }, [users]);

      return(
        <> 
        <div className="container">
         <h1 style={{color:"#51abe9", marginTop:"2%", marginBottom:"20px", fontFamily:"poppins"}}>Osiągnięcia użytkowników</h1>
{users.length ?
<div style={{display:'flex', justifyContent:"center", alignItems:"baseline", gap:"20px"}}>
<table id="customers">
  <tr>
    <th>Imię</th>
    <th>Nazwisko</th>
    <th>Poziom nauki</th>
    <th>Nieudane próby zdania</th>
    <th>Akcja</th>
  </tr>

  {users
  .filter((user) => user.user_id !== info.user.id )
  .map((user) => {
  //  for(let i = 0;i<user.failed_attempts;i++){
  //  }  
    return(
      <>
        <tr key={user.id}>
          <td style={{fontSize:"15px", fontFamily:"poppins"}}>{user.user_id !== info.user.id  ? user.name: null}</td>
          <td style={{fontSize:"15px", fontFamily:"poppins"}}>{user.user_id !== info.user.id  ? user.surname: null}</td>
          <td style={{fontSize:"15px", fontFamily:"poppins"}}>{user?.level}</td>
          <td>
            <p style={{paddingTop:"17px", fontSize:"16px", fontFamily:"poppins"}}> { user.failed_attempts !== null ? user.failed_attempts.split(",")[activeIndex] : null }<br></br></p> 
          </td>
          <td><button style={{backgroundColor:"red", width:"200px", fontSize:"14px", height:"36px", marginTop:"0px"}}  className="login-user-button" onClick={() => deleteUser(user.userId)}>Usuń</button></td>
        </tr>
      </>
    )
  })}
</table>
<select onChange={onChange} required>
  <option value=""></option>
  <option value="0">Lekcja nr 1</option>
  <option value="1">Lekcja nr 2</option>
  <option value="2">Lekcja nr 3</option>
  <option value="3">Lekcja nr 4</option>
  <option value="4">Lekcja nr 5</option>
  <option value="5">Lekcja nr 6</option>
  <option value="6">Lekcja nr 7</option>
  <option value="7">Lekcja nr 8</option>
  <option value="8">Lekcja nr 9</option>
  <option value="9">Lekcja nr 10</option>
  </select> 
  </div>
: <div>Brak rekordów</div> }
        </div>
        <Footer/>
        </>
    )

}
export default AllUsers;