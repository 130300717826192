import { useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { BACKEND_URL } from "../constants"
import cogoToast from "cogo-toast"
import Footer from "../Footer"

const fieldCSS = {
  display: 'flex',
  alignItems: 'center'
}

const Quiz = () => {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams()
    const user = JSON.parse(sessionStorage.getItem("user"));
    const initialized = useRef(false);
    const navigate = useNavigate()
    const [users,setUsers] = useState([])
    const [data, setData] = useState([]);
    let table = []

      const onChange = (e) => {
        if(e.target.name === `answer${e.target.id}`){
          if(table[e.target.id] !== ""){
            table[parseInt(e.target.id) === 1 ? parseInt(e.target.id) - 1: parseInt(e.target.id-1)] = e.target.value
          }
        }
      } 
      const chooseAnswer = async () => {
        const correctAnswers = data.filter((info,index) => info.answer_true === table[index]);
        if(correctAnswers.length < data.length - 1){
          cogoToast.info(`Niestety zdobyłeś ${correctAnswers.length} pkt a maksymalnie do zdobycia jest ${data.length} pkt, możesz stracić tylko jeden punkt`)
          try{
            const response = await fetch(`${BACKEND_URL}/failed`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: user.user.userId,
              presentation_id: searchParams.get("id"),
              failed: 1
            }),
          });
          const result = await response.json();
    
          if (result.statusCode === 200) {
            setLoading(false)
            cogoToast.success(
              `Próba zdania została zapisana`
            );
            navigate("/");
          } else {
            cogoToast.error("Nieprawidłowe dane");
          } 
        }
        catch (error) {
          console.error("error: ", error);
        }}
        else{
          try{
            const response = await fetch(`${BACKEND_URL}/levelUp`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: user.user.userId,
              presentation_id: searchParams.get("id"),
              user_level: users?.level,
            }),
          });
          const result = await response.json();
    
          if (result.statusCode === 200) {
            setLoading(false)
            cogoToast.success(
              `Gratulacje masz ${correctAnswers.length}pkt, zdobyłeś nowy poziom`
            );
            navigate("/");
          } else {
            cogoToast.error("Nieprawidłowe dane");
          } 
        }
        catch (error) {
          console.error("erorr: ", error);
        }}
      }

      useEffect(() => {
            fetch(`${BACKEND_URL}/users/${user.user.userId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": true,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.hasOwnProperty("error")) {
                  setUsers(response[0]);
                }
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => setLoading(false));
          },[user.user.userId])

        useEffect(() => {
          if (!initialized.current) {
            initialized.current = true;
            setLoading(true);
            setTimeout(() => {
              fetch(`${BACKEND_URL}/quiz/${searchParams.get("id")}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": true,
                },
              })
                .then((response) => response.json())
                .then((response) => {
                  if (!response.hasOwnProperty("error")) {
                    setData(response.data);
                  }
                })
                .catch((error) => {
                  console.error("error: ", error);
                })
                .finally(() => setLoading(false));
            }, [1000]);
          }
        }, [data,searchParams]); 

    return(
      <>
        <div style={{paddingBottom:"20px", display:"flex", flexFlow:"column"}}>
            <form>
                            {data.map((quiz,index) => {
                              return(
                                <div key={index} id={index+1} className="box" style={{display:'flex', paddingTop:"30px", flexFlow:"column", alignItems:"baseline"}}>
                              <p className="quiz-question-text">{index+1}. {quiz.name}</p>
                              <div className="box-inside" style={{display:"flex",flexFlow:"column", alignItems:"baseline"}}>
                                <div className="box-in" style={fieldCSS}>
                                a)
                                <div style={{display:"flex", paddingLeft:"6px", gap:"3px"}}>
                                  <input id={index+1} onChange={onChange} type="radio" name={`answer${index+1}`} value="a"/>
                                  <label>{quiz.answer_a}</label>
                                </div>
                                </div>
                                <div style={fieldCSS}>
                                b)
                                <div style={{display:"flex", paddingLeft:"6px", gap:"3px"}}>
                                <input id={index+1} onChange={onChange} type="radio" name={`answer${index+1}`} value="b"/>
                                <label>{quiz.answer_b}</label>
                                </div>
                                </div>
                                <div style={fieldCSS}>
                                c)
                                <div style={{display:"flex", paddingLeft:"6px", gap:"3px"}}>
                                <input id={index+1} onChange={onChange}  type="radio" name={`answer${index+1}`} value="c"/>
                                <label>{quiz.answer_c}</label>
                                </div>
                                </div>
                                <div style={fieldCSS}>
                                d)
                                <div style={{display:"flex", paddingLeft:"6px", gap:"3px"}}>
                                <input id={index+1} onChange={onChange}  type="radio" name={`answer${index+1}`} value="d"/>
                                <label>{quiz.answer_d}</label>
                                </div>
                                </div>
                              </div>
                              <br></br>
                            </div>
                              )
                            })}
                            {/* <div style={{display:'flex', flexFlow:"column", alignItems:"baseline"}}>
                              <p>{data[1]?.name}</p>
                              <div style={{display:"flex",flexFlow:"column", alignItems:"baseline"}}>
                                <div style={{display:"flex"}}>
                                a)<input onChange={onChange} type="checkbox" id="html" name="answer2" value="a"/>
                                <label htmlFor="html">{data[1]?.answer_a}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                b)<input onChange={onChange}  type="checkbox" id="css" name="answer2" value="b"/>
                                <label htmlFor="css">{data[1]?.answer_b}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                c)<input onChange={onChange}  type="checkbox" id="css"name="answer2"value="c"/>
                                <label htmlFor="css">{data[1]?.answer_c}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                d)<input onChange={onChange}  type="checkbox" id="css" name="answer2" value="d"/>
                                <label htmlFor="css">{data[1]?.answer_d}</label>
                                </div>
                              </div>
                            </div>
                            <div style={{display:'flex', flexFlow:"column", alignItems:"baseline"}}>
                              <p>{data[2]?.name}</p>
                              <div style={{display:"flex",flexFlow:"column", alignItems:"baseline"}}>
                                <div style={{display:"flex"}}>
                                a)<input onChange={onChange} type="checkbox" id="html" name="answer3" value="a"/>
                                <label htmlFor="html">{data[2]?.answer_a}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                b)<input onChange={onChange}  type="checkbox" id="css" name="answer3" value="b"/>
                                <label htmlFor="css">{data[2]?.answer_b}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                c)<input onChange={onChange}  type="checkbox" id="css"name="answer3" value="c"/>
                                <label htmlFor="css">{data[2]?.answer_c}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                d)<input onChange={onChange}  type="checkbox" id="css" name="answer3" value="d"/>
                                <label htmlFor="css">{data[2]?.answer_d}</label>
                                </div>
                              </div>
                            </div>
                            <div style={{display:'flex', flexFlow:"column", alignItems:"baseline"}}>
                              <p>{data[3]?.name}</p>
                              <div style={{display:"flex",flexFlow:"column", alignItems:"baseline"}}>
                                <div style={{display:"flex"}}>
                                a)<input onChange={onChange} type="checkbox" id="html" name="answer4" value="a"/>
                                <label htmlFor="html">{data[3]?.answer_a}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                b)<input onChange={onChange}  type="checkbox" id="css" name="answer4" value="b"/>
                                <label htmlFor="css">{data[3]?.answer_b}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                c)<input onChange={onChange}  type="checkbox" id="css"name="answer4" value="c"/>
                                <label htmlFor="css">{data[3]?.answer_c}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                d)<input onChange={onChange}  type="checkbox" id="css" name="answer4" value="d"/>
                                <label htmlFor="css">{data[3]?.answer_d}</label>
                                </div>
                              </div>
                            </div>
                            <div style={{display:'flex', flexFlow:"column", alignItems:"baseline"}}>
                              <p>{data[4]?.name}</p>
                              <div style={{display:"flex", flexFlow:"column", alignItems:"baseline"}}>
                              <div style={{display:"flex"}}>
                                a)<input onChange={onChange} type="checkbox" id="html" name="answer5" value="a"/>
                                <label htmlFor="html">{data[4]?.answer_a}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                b)<input onChange={onChange}  type="checkbox" id="css" name="answer5" value="b"/>
                                <label htmlFor="css">{data[4]?.answer_b}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                c)<input onChange={onChange}  type="checkbox" id="css"name="answer5" value="c"/>
                                <label htmlFor="css">{data[4]?.answer_c}</label>
                                </div>
                                <div style={{display:"flex"}}>
                                d)<input onChange={onChange}  type="checkbox" id="css" name="answer5" value="d"/>
                                <label htmlFor="css">{data[4]?.answer_d}</label>
                                </div>
                            </div> */}
             </form>
             <button style={{marginTop:"20px"}} className="login-user-button" onClick={chooseAnswer}>Wyślij odpowiedzi</button>
             </div>
             <Footer/>
             </>
    )
}
export default Quiz