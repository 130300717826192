import cogoToast from 'cogo-toast'
import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../Footer'
import PagingControl from "./PaggingControl";

const PdfViewer = () => {
    const [numPages, setNumPages] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const pageDetailsRef = useRef(null);
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


    const handlePageNum = (pageNum) => {
      setPageNum(pageNum);
    }

    const toQuiz = () => {
      navigate(`/quiz?id=${searchParams.get('id')}`)
    }
  
    function onDocumentLoadSuccess({ numPages }) {
      cogoToast.loading("Trwa ładowanie stron, prosze poczekać")
      setTimeout(() => {
        setNumPages(numPages);
      },[1000])
    }
  
    return (
      <>
      <div>
        <br></br>
        <Document file={searchParams.get('link')} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
                pageNumber={pageNum}
                width={1000}
                height={450} 
                onLoadSuccess={(data) => {
                  pageDetailsRef.current = data;
                }}
              />
        </Document>
        <br></br>
       <button style={{marginTop:"0px", backgroundColor:pageNum < numPages && "grey"}}  onClick={toQuiz} className="login-user-button" disabled={pageNum < numPages}>Przejdz do quizu</button>
        <PagingControl
            pageNum={pageNum}
            onPageChange={handlePageNum}
            totalPages={numPages}
          />
      </div>
      <Footer/>
      </>
    );
}
export default PdfViewer;